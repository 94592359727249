'use client'
import { GoogleButton, PlainButton, PlandaButton, ProvidersContainer } from '@/components/SignInButtons'
import { GOOGLE_CALENDAR_SCOPES } from '@/constants/external'
import useMeeting from '@/hooks/useMeeting'
import { CenterPage } from '@/styles/common'
import { signIn, useSession } from 'next-auth/react'
import React from 'react'
import { styled } from '@/stitches.config'
import { useRouter } from 'next/navigation'
import { useGlobalUser } from '@/hooks/useUser'
import { fetcher } from '@/lib/fetch'

const Sync = ({ params: { id } }: { params: { id: string } }) => {
    const router = useRouter()
    const { name } = useGlobalUser()
    const { meetingDurationLabel, meetingId } = useMeeting(id)
    const callbackUrl = `/${meetingId}/join?`
    const { status } = useSession()

    if (name && status === 'authenticated') {
        // fetcher(`/api/${meetingId}/participant`, 'PUT')
        fetcher(`/api/${meetingId}/participant`, 'PUT', { name }).then(() => {
            router.push(callbackUrl + `name=${name}`)
            router.push(`/${meetingId}/availabilities?editing=true&name=${name}`)
        })
        return <Page>Account detected, signing you in...</Page>
    }

    return (
        <Page>
            <h1>Meeting for {meetingDurationLabel}!</h1>
            <ProvidersContainer>
                <PlandaButton text='Sync with Planda' onClick={() => signIn(undefined, { callbackUrl })} />
                <GoogleButton text='Sync with Google' onClick={async () => {
                    await signIn('google', {
                        callbackUrl,
                        // redirect: false,
                    }, {
                        scope: GOOGLE_CALENDAR_SCOPES.join(' '),
                        prompt: 'consent', access_type: 'offline',
                    })
                    // window.confirm('You have successfully synced with Google Calendar!')
                    // router.push(callbackUrl + `&editing=true`)
                }} />
                <PlainButton text='Skip' onClick={() => { router.push(callbackUrl) }} />
            </ProvidersContainer>
            {/* <span>or</span>
            <LinkButton color={'contrast'}
                href={`/TODO`} // TODO
                css={{
                    backgroundColor: '$loContrast',
                    color: '$gray12',
                }}
            >Sign in</LinkButton> */}
        </Page>
    )
}

export default Sync


const Page = styled(CenterPage, {
    gap: 50
})