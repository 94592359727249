import { styled } from "@stitches/react";

export const CenterPage = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    background: '$loContrast',
    position: 'relative',
    boxSizing: 'border-box',
    flexDirection: 'column'
})